<template>
    <div class="company-persons list-group bg-white shadow">
        <div class="list-group-item"
            :key="person.id"
            v-for="person in people"
        >
            <div class="row align-content-center align-items-center">
                <div class="col-12 col-sm-9 col-md-10">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.fullname') }}</div>
                            {{ person.name }} {{ person.surname }}
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.rc') }}</div>
                            <span>{{ person.birthDate ? new Date(person.birthDate).toLocaleDateString() : person.birthDate }}</span>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.address') }}</div>
                            {{ person.addressStreet }}, {{ person.addressZip }} {{ person.addressCity }}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.isRealOwner') }}</div>
                            {{ relationshipDetail(person) }}
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="font-weight-bold text-primary">{{ $t('realOwners.list.isStatutory') }}</div>
                            {{ statutoryType(person) }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-2 text-md-right whitespace-nowrap mt-3 mt-md-0 pr-3">
                    <b-btn
                        :title="$t('realOwners.list.edit')"
                        variant="primary"
                        size="sm"
                        @click.prevent="onEdit(person)"
                    >
                        <b-icon icon="pencil"></b-icon>
                    </b-btn>
                    <b-btn
                        :title="$t('realOwners.list.remove')"
                        variant="danger"
                        size="sm"
                        @click.prevent="onDelete(person)"
                        class="ml-2"
                    >
                        <b-icon icon="trash"></b-icon>
                    </b-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RealOwnersList',
    props: {
        people: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    methods: {
        onEdit (person) {
            this.$emit('edit', person)
        },
        onDelete (person) {
            this.$swal.warning(
                this.$t('personList.confirm.title'),
                this.$t('personList.confirm.text'),
                this.$t('personList.confirm.delete'),
                this.$t('personList.confirm.cancel')
            ).then((value) => {
                if (value === 'ok') {
                    this.$emit('delete', person)
                }
            }).catch(() => { /* nothing */ })
        },
        relationshipDetail (person) {
            return person.isRealOwner ? person.relationshipDetail : this.$t('app.no')
        },
        statutoryType (person) {
            return person.isStatutory ? this.$t(`statutoryTypes.${person.statutoryType}`) : this.$t('app.no')
        }
    }
}
</script>

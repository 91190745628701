<template>
    <div>
        <h4>{{$t('realOwners.form.subheading')}}</h4>
        <b-form @submit.prevent="onSubmit" novalidate>
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'realOwner.name'"
                        :label="$t('basicData.name')"
                        v-model="name"
                        :validation="$v.name"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'realOwner.surname'"
                        :label="$t('basicData.surname')"
                        v-model="surname"
                        :validation="$v.surname"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input-date-picker
                        :id="'realOwner.birthDate'"
                        :label="$t('basicData.birthDate')"
                        v-model="birthDate"
                        :max-date="new Date()"
                        :locale="datePickerLang"
                        :validation="$v.birthDate"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-input-select
                        :id="'realOwner.citizenship'"
                        :label="$t('realOwners.form.citizenship')"
                        v-model="citizenship"
                        :options="countries"
                        :validation="$v.citizenship"
                    />
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'realOwner.addressStreet'"
                        :label="$t('basicData.addressStreet')"
                        v-model="addressStreet"
                        :validation="$v.addressStreet"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'realOwner.addressCity'"
                        :label="$t('basicData.addressCity')"
                        v-model="addressCity"
                        :validation="$v.addressCity"
                    />
                </div>
                <div class="col-12 col-md-4">
                    <form-input
                        :id="'realOwner.addressZip'"
                        :label="$t('basicData.addressZip')"
                        v-model="addressZip"
                        :validation="$v.addressZip"
                        :validation-messages="{ custom: $t('validation.invalidZipLength') }"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-input-select
                        :id="'realOwner.addressCountry'"
                        :label="$t('basicData.addressCountry')"
                        v-model="addressCountry"
                        :options="countries"
                        :validation="$v.addressCountry"
                    />
                </div>

                <div class="col-12 col-md-8">
                    <form-input-checkbox
                        class="mt-4 pt-2"
                        :id="'realOwner.politician'"
                        v-model="politician"
                    >
                        <template>
                            {{ $t('basicData.politician') }}
                            <b-icon id="infoPopover" @click.prevent.stop="showPopover = !showPopover" icon="info-circle" class="ml-1"/>
                        </template>
                    </form-input-checkbox>

                    <b-popover
                        :show.sync="showPopover"
                        :custom-class="'popover-large'"
                        target="infoPopover"
                        :triggers="['hover', 'focus']"
                        placement="bottom"
                    >
                        {{ $t('basicData.politicianInfo') }}
                    </b-popover>
                </div>
            </div>

            <form-input
                :id="'realOwner.countryOrigin'"
                :label="$t('basicData.countryOrigin')"
                v-model="countryOrigin"
                :validation="$v.countryOrigin"
            />
            <i18n path="basicData.countryOriginInfoText.other.text"
                class="alert alert-primary small"
                tag="p"
            >
                <strong>{{ $t('basicData.countryOriginInfoText.other.text1Strong') }}</strong>
                <strong>{{ $t('basicData.countryOriginInfoText.other.text2Strong') }}</strong>
                <strong>{{ $t('basicData.countryOriginInfoText.other.text3Strong') }}</strong>
                <strong>{{ $t('basicData.countryOriginInfoText.other.text4Strong') }}</strong>
            </i18n>

            <form-input-checkbox-group
                :id="'realOwner.personCategories'"
                :label="$t('realOwners.form.personCategories')"
                v-model="personCategories"
                :options="personCategoriesOptions"
                :validation="$v.personCategories"
                :stacked="false"
            />

            <div class="row">
                <div
                    v-if="isStatutory"
                    class="col-12 col-md-6"
                >
                    <form-input-select
                        :id="'realOwner.statutoryType'"
                        :label="$t('realOwners.statutoryType')"
                        v-model="statutoryType"
                        :options="statutoryTypes"
                        :validation="$v.statutoryType"
                    />
                </div>
                <div
                    v-if="isRealOwner"
                    class="col-12 col-md-6"
                >
                    <form-input
                        :id="'realOwner.relationshipDetail'"
                        v-model="relationshipDetail"
                        :label="$t('realOwners.form.relationshipDetail')"
                        :validation="$v.relationshipDetail"
                        :hint="$t('realOwners.form.relationshipDetailReason')"
                    />
                </div>
            </div>

            <div class="mt-5">
                <b-btn
                    type="submit"
                    variant="primary"
                    class="mr-4 mb-2"
                >
                    {{$t('app.submit')}}
                </b-btn>

                <b-btn
                    variant="light"
                    @click.prevent="onCancel"
                    class="mb-2"
                >
                    {{$t('app.cancel')}}
                </b-btn>
            </div>

        </b-form>
    </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import * as objectHash from 'object-hash'

export default {
    name: 'RealOwnerForm',
    props: {
        editPerson: {
            required: false,
            default: null
        },
        defaults: {
            type: Object,
            required: false,
            default: () => ({})
        },
        datePickerLang: {
            type: String,
            required: false,
            default: 'cz'
        },
        realOwnerTypes: {
            type: Array,
            required: true
        },
        showValidation: {
            type: Boolean,
            required: true
        },
        realOwnerUndeterminable: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            id: this.defaults._id || null,
            name: this.defaults.name || null,
            surname: this.defaults.surname || null,
            birthDate: this.defaults.birthDate || null,
            addressStreet: this.defaults.addressStreet || null,
            addressCity: this.defaults.addressCity || null,
            addressZip: this.defaults.addressZip || null,
            addressCountry: this.defaults.addressCountry || null,
            politician: this.defaults.politician || false,
            citizenship: this.defaults.citizenship || null,
            countryOrigin: this.defaults.countryOrigin || null,
            isStatutory: this.defaults.isStatutory || false,
            statutoryType: this.defaults.statutoryType || null,
            relationshipDetail: this.defaults.relationshipDetail || null,
            isRealOwner: this.defaults.isRealOwner || false,
            showPopover: false
        }
    },
    computed: {
        ...mapGetters(['countries', 'statutoryTypes']),
        isValid () {
            return !this.$v.$invalid
        },
        dataHash () {
            return objectHash.sha1(JSON.parse(JSON.stringify(this.$data)))
        },
        personCategories: {
            get () {
                return [
                    this.isRealOwner ? 'isRealOwner' : undefined,
                    this.isStatutory ? 'isStatutory' : undefined
                ].filter(item => item)
            },
            set (value) {
                this.isRealOwner = value.includes('isRealOwner')
                this.isStatutory = value.includes('isStatutory')
            }
        },
        personCategoriesOptions () {
            return [
                {
                    value: 'isStatutory',
                    text: this.$t('realOwners.form.isStatutory')
                },
                {
                    value: 'isRealOwner',
                    text: this.$t('realOwners.form.isRealOwner'),
                    disabled: this.realOwnerUndeterminable === true
                }
            ]
        }
    },
    watch: {
        defaults () {
            this.$nextTick(() => {
                this.id = this.defaults._id || null
                this.name = this.defaults.name || null
                this.surname = this.defaults.surname || null
                this.birthDate = this.defaults.birthDate || null
                this.addressStreet = this.defaults.addressStreet || null
                this.addressCity = this.defaults.addressCity || null
                this.addressZip = this.defaults.addressZip || null
                this.addressCountry = this.defaults.addressCountry || null
                this.politician = this.defaults.politician || false
                this.citizenship = this.defaults.citizenship || null
                this.countryOrigin = this.defaults.countryOrigin || null
                this.isStatutory = this.defaults.isStatutory || false
                this.statutoryType = this.defaults.statutoryType || null
                this.relationshipDetail = this.defaults.relationshipDetail || null
                this.isRealOwner = this.defaults.isRealOwner || false
            })
        },
        dataHash (newValue) {
            this.$store.commit('setDataHash', { name: 'realOwner', hash: newValue })
        }
    },
    mounted () {
        this.$store.commit('setDataHash', { name: 'realOwnerOld', hash: ((Object.keys(this.defaults).length > 0) ? `${this.dataHash}` : null) })
        this.$store.commit('setDataHash', { name: 'realOwner', hash: null })
        if (this.showValidation) {
            this.$nextTick(() => {
                this.$v.$touch()
            })
        }
    },
    methods: {
        onCancel () {
            this.onReset()
            this.$store.commit('setDataHash', { name: 'realOwnerOld', hash: null })
            this.$store.commit('setDataHash', { name: 'realOwner', hash: null })
            this.$emit('cancel')
        },
        onReset () {
            this.id = null
            this.name = null
            this.surname = null
            this.birthDate = null
            this.addressStreet = null
            this.addressCity = null
            this.addressZip = null
            this.addressCountry = null
            this.politician = false
            this.citizenship = null
            this.countryOrigin = null
            this.isStatutory = false
            this.statutoryType = null
            this.relationshipDetail = null
            this.isRealOwner = false
        },
        onSubmit () {
            this.$v.$reset()
            this.$nextTick(() => {
                if (this.$v.$invalid) {
                    this.$notify.error(this.$t('errors.someDataIsMissing'))
                    this.$v.$touch()
                } else {
                    const { id, showPopover, ...data } = JSON.parse(JSON.stringify(this.$data))
                    data._id = id
                    this.$emit('submit', data)
                }
            })
        }
    },
    validations () {
        return {
            name: {
                required
            },
            surname: {
                required
            },
            addressStreet: {
                required
            },
            addressCity: {
                required
            },
            addressCountry: {
                required
            },
            citizenship: {
                required
            },
            addressZip: {
                required,
                custom (value) {
                    return (value && value.replace(/\s/g, '').length >= 5)
                }
            },
            birthDate: {
                required
            },
            countryOrigin: {
                required
            },
            statutoryType: {
                required: requiredIf(function () {
                    return this.isStatutory
                })
            },
            relationshipDetail: {
                required: requiredIf(function () {
                    return this.isRealOwner
                })
            },
            personCategories: {
                required
            }
        }
    }
}
</script>
<style>
    .popover.popover-large {
        max-width: 80vw;
    }
</style>
